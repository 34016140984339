import React from "react";
import { IdleQueue } from "idlize/IdleQueue.mjs";
import { isBrowser } from "react-device-detect";
import cn from "classnames";

import { introflare } from "./IntroFlare.module.scss";

export function IntroFlare({ currentSlide }) {
  React.useEffect(() => {
    const queue = new IdleQueue();

    queue.pushTask(() => {
      if (isBrowser) {
        // Some random colors
        const colors = ["#fff"];

        const numBalls = 25;
        const balls = [];

        for (let i = 0; i < numBalls; i++) {
          const introflare = document.getElementById("introflare");
          let ball = document.createElement("div");
          ball.classList.add("ball");
          ball.style.background =
            colors[Math.floor(Math.random() * colors.length)];
          ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
          ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
          ball.style.transform = `scale(${Math.random()})`;
          ball.style.width = `${Math.random() * 3}em`;
          ball.style.height = ball.style.width;

          balls.push(ball);
          introflare.append(ball);
        }

        // Keyframes
        balls.forEach((el, i, ra) => {
          let to = {
            x: Math.random() * (i % 2 === 0 ? -11 : 11),
            y: Math.random() * 12,
          };

          // eslint-disable-next-line no-unused-vars
          let anim = el.animate(
            [
              { transform: "translate(0, 0)" },
              { transform: `translate(${to.x}rem, ${to.y}rem)` },
            ],
            {
              duration: (Math.random() + 1) * 2000, // random duration
              direction: "alternate",
              fill: "both",
              iterations: 1, // change to an integer
              easing: "ease-in-out",
            }
          );
        });
      }
    });
  }, []);

  return <div className={cn(introflare, "absolute")} id="introflare"></div>;
}
