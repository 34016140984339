import * as React from "react";
import { formatPrice } from "../../helpers/formatPrice";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import { MdDelete } from "react-icons/md";
import { NumericInput } from "../../components";
import { Link } from "gatsby";
import cn from "classnames";

import { styles } from "./LineItem.module.scss";

export function LineItem({
  item,
  removeLineItem,
  checkout,
  updateLineItem,
  loading,
}) {
  const [quantity, setQuantity] = React.useState(item.quantity);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      updateLineItem(checkout.id, item.id, value);
    }
  };

  const handleRemove = () => {
    console.log("handleRemove", checkout, item.id);
    removeLineItem(checkout.id, item.id);
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  );

  return (
    <tbody>
      <tr className="table-row lg:hidden">
        <td>
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </td>
        <td colSpan={2}>
          <h2 className="text-sm text-left">
            <Link to="/store/">{item.title}</Link>
          </h2>
        </td>
      </tr>
      <tr className={cn(styles)}>
        <td className="py-6 hidden lg:table-cell">
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </td>
        <td className="text-left hidden lg:table-cell">
          <h2 className="text-sm">
            <Link to="/store/">{item.title}</Link>
          </h2>
        </td>
        <td className="hidden lg:table-cell">{price}</td>
        <td className="pb-20 lg:pb-0">
          <NumericInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={(e) => handleQuantityChange(e.currentTarget.value)}
          />
        </td>
        <td className="pb-20 lg:pb-0 text-left lg:text-center">{subtotal}</td>
        <td className="pb-20 lg:pb-0">
          <button type="button" onClick={handleRemove} className="text-lg p-3">
            <MdDelete />
          </button>
        </td>
      </tr>
    </tbody>
  );
}
