import React from "react";
import { StoreContext } from "context/StoreContext";
import { Link } from "gatsby";
import { formatPrice } from "../../helpers/formatPrice";
import { LineItem, Spinner } from "../../components";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import { styles } from "./CartContents.module.scss";

export const CartContents = () => {
  const { removeLineItem, checkout, updateLineItem } =
    React.useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;
  const [loading, setLoading] = React.useState(true);
  const start = Date.now();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 600);
    const millis = Date.now() - start;
    console.log(checkout.lineItems);
    console.log(`miliseconds elapsed = ${millis}`);

    return () => clearTimeout(timeout);
  }, [setLoading, emptyCart, start, checkout.lineItems]);

  /* eslint-disable jsx-a11y/control-has-associated-label */
  return (
    <section
      className={cn(
        styles,
        "container lg:mx-auto lg:flex items-center content-center min-h-screen lg:pb-40 pt-40 lg:pt-56 lg:px-0 px-6"
      )}
    >
      <div className="text-center w-full">
        {emptyCart ? (
          <>
            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <>
                <h1 className="pb-20">You cart is empty.</h1>
                <Link to="/store/">Continue shopping</Link>
              </>
            )}
          </>
        ) : (
          <>
            <div className="lg:grid grid-flow-row-dense grid-cols-1 lg:grid-cols-3 gap-40">
              <div className="col-span-2">
                <motion.h1
                  className="mb-20 text-left leading-normal border-b-4 border-gray-100"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                >
                  Order summary
                </motion.h1>
                <table className="w-full">
                  <thead className="hidden lg:table-header-group">
                    <tr>
                      <th></th>
                      <th width="40%"></th>
                      <th>Price</th>
                      <th>Qty.</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  {checkout.lineItems.map((item) => (
                    <LineItem
                      item={item}
                      key={item.id}
                      removeLineItem={removeLineItem}
                      checkout={checkout}
                      updateLineItem={updateLineItem}
                      loading={loading}
                    />
                  ))}
                </table>
              </div>
              <AnimatePresence>
                <motion.div
                  className={cn(styles)}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ ease: "easeInOut" }}
                >
                  <div className="border p-10">
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td className="text-left">Subtotal</td>
                          <td className="text-right">
                            {formatPrice(
                              checkout?.subtotalPriceV2.currencyCode,
                              checkout?.subtotalPriceV2.amount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Taxes</td>
                          <td className="text-right">
                            {formatPrice(
                              checkout?.totalTaxV2.currencyCode,
                              checkout?.totalTaxV2.amount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left pb-5">Shipping</td>
                          <td className="text-right pb-5 text-xs">
                            Calculated at checkout
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left pt-5 border-t text-lg">
                            <b>Total Price</b>
                          </td>
                          <td className="text-right pt-5 border-t text-lg">
                            <b>
                              {formatPrice(
                                checkout?.totalPriceV2.currencyCode,
                                checkout?.totalPriceV2.amount
                              )}
                            </b>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mt-20">
                      <a
                        href={checkout.webUrl}
                        className="px-8 py-4 bg-gray-200 hover:bg-gray-300  text-xl leading-none w-full block"
                      >
                        Checkout
                      </a>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
