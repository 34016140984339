import React from "react";
import IconSpinner from "../../images/spinner.svg";

export function Spinner({ loading }) {
  return (
    <>
      {loading && (
        <div className="text-center">
          <IconSpinner className="inline mr-2 w-10 h-10 text-gray-200 animate-spin fill-gray-600" />
        </div>
      )}
    </>
  );
}
