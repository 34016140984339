export function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

export function resetFlare(carouselRef) {
  const balls = carouselRef.current.querySelectorAll("#introflare .ball");

  balls.forEach((el, i, ra) => {
    const { x, y, z } = getTranslateValues(el); // eslint-disable-line no-unused-vars

    let from = {
      x: x,
      y: y,
    };

    let to = {
      x: Math.random() * (i % 2 === 0 ? -11 : 11),
      y: Math.random() * 12,
    };

    // eslint-disable-next-line no-unused-vars
    let anim = el.animate(
      [
        { transform: `translate(${from.x}, ${from.y})` },
        { transform: `translate(${to.x}rem, ${to.y}rem)` },
      ],
      {
        duration: (Math.random() + 1) * 2000, // random duration
        direction: "alternate",
        fill: "both",
        iterations: 1, // change to an integer
        easing: "ease-in-out",
      }
    );
  });
}

export function getTranslateValues(element) {
  const style = window.getComputedStyle(element);
  const matrix =
    style["transform"] || style.webkitTransform || style.mozTransform;

  // No transform property. Simply return 0 values.
  if (matrix === "none" || typeof matrix === "undefined") {
    return {
      x: 0,
      y: 0,
      z: 0,
    };
  }

  // Can either be 2d or 3d transform
  const matrixType = matrix.includes("3d") ? "3d" : "2d";
  const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");

  // 2d matrices
  if (matrixType === "2d") {
    return {
      x: matrixValues[4],
      y: matrixValues[5],
      z: 0,
    };
  }

  // 3d matrices
  if (matrixType === "3d") {
    return {
      x: matrixValues[12],
      y: matrixValues[13],
      z: matrixValues[14],
    };
  }
}
