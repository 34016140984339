import React from "react";
import IconSpinner from "../../images/spinner-xs.svg";
import cn from "classnames";

export const Button = ({
  onClick,
  isSubmitting,
  className,
  quantity,
  children,
}) => {
  let btnClass = cn(
    className,
    "px-6 py-2 bg-gray-300 leading-none w-full lg:w-auto flex items-center relative",
    { "cursor-not-allowed": isSubmitting },
    { "cursor-not-allowed": quantity === 0 },
    { "hover:bg-gray-400": quantity > 0 }
  );

  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={isSubmitting || quantity === 0}
      className={btnClass}
    >
      {isSubmitting && (
        <span className="block absolute left-0 right-0 mx-auto w-4">
          <IconSpinner />
        </span>
      )}
      <span
        className={cn(
          isSubmitting ? "invisible" : "visible",
          "inline-block mx-2"
        )}
      >
        {children}
      </span>
    </button>
  );
};
