import * as React from "react";

export function NumericInput({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) {
  return (
    <div>
      <div className="flex flex-row justify-center w-full relative bg-transparent px-10">
        <button
          onClick={onDecrement}
          disabled={disabled}
          aria-label="Decrement"
          type="button"
          className=" bg-transparent h-full w-8 cursor-pointer outline-none"
        >
          <span className="m-auto text-2xl">−</span>
        </button>
        <input
          disabled={disabled}
          type="number"
          min={0}
          {...props}
          className="outline-none focus:outline-none text-center w-10 bg-transparent text-sm hover:text-black focus:text-black md:text-basecursor-default flex items-center"
        />
        <button
          onClick={onIncrement}
          disabled={disabled}
          aria-label="Increment"
          type="button"
          className="bg-transparent h-full w-8 cursor-pointer"
        >
          <span className="m-auto text-2xl">+</span>
        </button>
      </div>
    </div>
  );
}
