import React,{ useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IdleQueue } from "idlize/IdleQueue.mjs";
import Carousel from "nuka-carousel";
import { BrowserView } from "react-device-detect";
import cn from "classnames";
import { IntroHeading, IntroOverlay } from "components";
import { resetFlare } from "../../helpers/utils";

import { intro } from "./Intro.module.scss";
import ReactPlayer from "react-player";
import {IntroLinks} from "../IntroLinks/IntroLinks";

export function Intro({ slides, overlay }) {
  const carouselRef = React.useRef();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [showLeaves, setShowLeaves] = React.useState(true);
  const [playing,setPlaying] = React.useState(false);

  function beforeSlideHandler(slideIndex) {
    setCurrentSlide(currentSlide + 1);
    resetFlare(carouselRef);
  }

  function afterSlideHandler(slideIndex) {
    if(slides[slideIndex]?.node?.backgroundVideo) {
        setShowLeaves(false);
        setPlaying(true);
    }else{
        setShowLeaves(true);
        setPlaying(false);
    }
    const current = carouselRef.current.querySelectorAll(
      ".slider-slide.slide-current .heading h1, .slider-slide.slide-current .heading h2, .slider-slide.slide-current .heading h3, .slider-slide.slide-current .heading h4, .slider-slide.slide-current .heading h5, .slider-slide.slide-current .heading h6, .slider-slide.slide-current .heading p"
    );

    const before = carouselRef.current.querySelectorAll(
      ".slider-slide .heading h1, .slider-slide .heading h2, .slider-slide .heading h3, .slider-slide .heading h4, .slider-slide .heading h5, .slider-slide .heading h6, .slider-slide .heading p"
    );

    before.forEach((text) => {
      text.classList.remove("animated");
    });

    current.forEach((text) => {
      text.classList.add("animated");
    });
  }

  useEffect(() => {
    const queue = new IdleQueue();
    queue.pushTask(() => {
      afterSlideHandler();
    });
  }, []);

  return (
    <section
      id="intro"
      ref={carouselRef}
      className={cn(intro, "h-screen overflow-hidden")}
    >
      { showLeaves && (<BrowserView> <IntroOverlay overlay={overlay} /> </BrowserView>) }
      <Carousel
        transitionMode="fade"
        heightMode="max"
        enableKeyboardControls={true}
        autoplay={true}
        wrapAround={true}
        autoplayInterval={10000}
        beforeSlide={(slideIndex) => beforeSlideHandler(slideIndex)}
        afterSlide={(slideIndex) => afterSlideHandler(slideIndex)}
        defaultControlsConfig={{
          nextButtonText: "▶",
          prevButtonText: "◀",
          pagingDotsStyle: {
            fill: "white",
          },
        }}
      >
        {slides.map(({ node: { id, title, content, background, backgroundVideo,productSpecs,buildInstructions, logo } }) => (
          <div className="relative" key={id}>
            <IntroHeading logo = {logo} >{content}</IntroHeading>
            {/*{productSpecs?.file && productSpecs.file.url && buildInstructions?.file && buildInstructions.file.url && (
              <IntroLinks>
                <a href={`https://${productSpecs.file.url}`}>Product specs</a>
                <br/>
                <a href={`https://${buildInstructions.file.url}`}>Build instructions</a>
              </IntroLinks>
            )}*/}



            {/* { productSpecs && buildInstructions &&
                <IntroLinks>
                  <a href={`https://${productSpecs.file.url}`}>Product specs</a>
                  <br/>
                  <a href={`https://${buildInstructions.file.url}`}>Build instructions</a>
                </IntroLinks>
            } */}
            { background ?
                <GatsbyImage
                image={getImage(background)}
                alt={title}
                className="fixed -z-1 top-0 h-screen"/>
                : <ReactPlayer
                    height={'100%'}
                    width={'100%'}
                    playsInline
                    playing={playing}
                    muted
                    loop
                    url={`https://${backgroundVideo.file.url}`}
                />
            }
          </div>
        ))}
      </Carousel>
    </section>
  );
}
