import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { IdleQueue } from "idlize/IdleQueue.mjs";
import cn from "classnames";
import { gsap } from "gsap";

import { introOverlay } from "./IntroOverlay.module.scss";

export function IntroOverlay({ overlay }) {
  React.useEffect(() => {
    const queue = new IdleQueue();

    /* eslint-disable no-unused-vars */
    function touches(e) {
      var x = e.touches ? e.touches[0].clientX : e.clientX,
        y = e.touches ? e.touches[0].clientY : e.clientY;
      var w = window.innerWidth / 2;
      var h = window.innerHeight / 2;

      var r = -(x - w) / (w / 2) - 5;
      var l = -(x - w) / (w / 2) - 5;

      if (document.querySelector(".overlay-1")) {
        gsap.to(".overlay-1", {
          duration: 1,
          left: l + "%",
          ease: "expo.out",
        });
      }

      if (document.querySelector(".overlay-2")) {
        gsap.to(".overlay-2", {
          duration: 1,
          right: r + "%",
          ease: "expo.out",
        });
      }
    }

    queue.pushTask(() => {
      window.addEventListener("mousemove", touches);
      window.addEventListener("touchstart", touches);
      window.addEventListener("touchmove", touches);

      return () => {
        window.removeEventListener("mousemove", touches);
        window.removeEventListener("touchstart", touches);
        window.removeEventListener("touchmove", touches);
      };
    });
  }, []);

  return (
    <div className={cn(introOverlay, "absolute")}>
      {/* {overlay && (
        <>
          <div className="overlay overlay-1 absolute z-10 bottom-0">
            <StaticImage
              src="../../images/leaf-left.png"
              alt="SB5D"
              placeholder="blurred"
              layout="fixed"
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </div>

          <div className="overlay overlay-2 absolute z-10 bottom-0 right-0">
            <StaticImage
              src="../../images/leaf-right.png"
              alt="SB5D"
              placeholder="blurred"
              layout="fixed"
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </div>
        </>
      )} */}
    </div>
  );
}
