import React from "react";
import { Link } from "gatsby";
import cn from "classnames";
import { scaleDown as Menu } from "react-burger-menu";
import { Cart, Toast } from "components/";
import { StoreContext } from "../../context/StoreContext";
import Logo from "../Logo/logo";

import { header } from "./Header.module.scss";

export function Header() {
  const { checkout, loading, didJustAddToCart, menuOpen, setMenuOpen } =
    React.useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  function handleIsOpen() {
    setMenuOpen(true);
  }

  function handleOnClose() {
    setMenuOpen(false);
  }

  React.useEffect(() => {
    if (menuOpen) {
      console.log("handleIsOpen");
      document.getElementById("page-wrap").classList.add("opacity-70");
      document.body.classList.add("h-screen", "overflow-hidden");
    } else {
      console.log("handleOnClose");
      document.getElementById("page-wrap").classList.remove("opacity-70");
      document.body.classList.remove("h-screen", "overflow-hidden");
    }
    return false;
  }, [menuOpen]);

  return (
    <>
      <header className={cn(header, "absolute w-full")}>
        <Toast show={loading || didJustAddToCart}>
          {!didJustAddToCart ? "Updating cart…" : "Added to cart…"}
        </Toast>

        <div className="grid grid-cols-2">
          <nav>
            <ul className="flex my-12 m-6 lg:m-12">
              <li className="mr-6 lg:mr-12">
                <button
                  onClick={() => handleIsOpen()}
                  className={cn(menuOpen ? "hidden" : "inline")}
                >
                  Menu
                </button>
                <button
                  onClick={() => handleOnClose()}
                  className={cn(!menuOpen ? "hidden" : "inline")}
                >
                  Close
                </button>
              </li>
              {/*<li>*/}
              {/*  <Cart*/}
              {/*    quantity={quantity}*/}
              {/*    didJustAddToCart={didJustAddToCart}*/}
              {/*    loading={loading}*/}
              {/*  />*/}
              {/*</li>*/}
            </ul>
          </nav>
          <div className="mr-6 lg:mr-12">
            <a href="/" aria-label="Homepage" className="logo block ml-auto">
              <Logo />
            </a>
          </div>
        </div>
      </header>
      <Menu
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={menuOpen}
        onClose={handleOnClose}
        className="text-center"
      >
        <Link
          to="/"
          onClick={() => handleOnClose()}
          className="transition-all duration-500 ease-in-out transform opacity-80 hover:scale-110 hover:opacity-100"
        >
          <b className="uppercase">What is R+R®?</b>
          <small className="block opacity-80">
            Learn more about the R+R® program
          </small>
        </Link>
        <Link
          to="/how-it-works/"
          onClick={() => handleOnClose()}
          className="transition-all duration-500 ease-in-out transform opacity-80 hover:scale-110 hover:opacity-100"
        >
          <b className="uppercase">How it works</b>
          <small className="block opacity-80">
            I got the R+R® box, now what?
          </small>
        </Link>
        <Link
          to="/what-is-allowed-into/"
          onClick={() => handleOnClose()}
          className="transition-all duration-500 ease-in-out transform opacity-80 hover:scale-110 hover:opacity-100"
        >
          <b className="uppercase">What's allowed into</b>
          <small className="block opacity-80">
            What can we deposit into the R+R® boxes?
          </small>
        </Link>
        {/*<Link*/}
        {/*  to="/store/"*/}
        {/*  onClick={() => handleOnClose()}*/}
        {/*  className="transition-all duration-500 ease-in-out transform opacity-80 hover:scale-110 hover:opacity-100"*/}
        {/*>*/}
        {/*  <b className="uppercase">Order boxes</b>*/}
        {/*  <small className="block opacity-80">Sign up for R+R® program</small>*/}
        {/*</Link>*/}
        <a
          // href="http://certification.rplusrprogram.com/"
          href="https://certification.rplusrprogram.com/"
          target="_blank"
          rel="noreferrer"
          className="transition-all duration-500 ease-in-out transform opacity-80 hover:scale-110 hover:opacity-100"
        >
          <b className="uppercase">Certification</b>
          <small className="block opacity-80">
            Get certified to be part of the program
          </small>
        </a>
      </Menu>
    </>
  );
}
