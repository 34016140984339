import React from "react";
import { StoreContext } from "context/StoreContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cn from "classnames";
import { motion } from "framer-motion";
import { useFormikContext, Formik, Form } from "formik"; // eslint-disable-line no-unused-vars
import { Persist } from "formik-persist"; // eslint-disable-line no-unused-vars
import { DisplayFormikState } from "../../helpers/DisplayFormikState"; // eslint-disable-line no-unused-vars
import {
  motionContainer,
  motionOptionsLeft,
  motionOptionsRight,
  motionOptionsDown,
} from "../../constants";
import { Button, QuantityAdjuster } from "components";

import { styles } from "./ProductsCard.module.scss";

export function ProductsCard({ product, index }) {
  const [quantity, setQuantity] = React.useState(0);
  const { checkout, addVariantToCart } = React.useContext(StoreContext);

  const onDecrement = (setFieldValue) => {
    if (quantity > 0) {
      setQuantity(parseInt(quantity - 1));
      setFieldValue("quantity", quantity - 1);
    }
  };

  const onIncrement = (setFieldValue) => {
    setQuantity(parseInt(quantity + 1));
    setFieldValue("quantity", quantity + 1);
  };

  const handleSubmit = (values) => {
    const quantity = values.quantity;

    if (checkout && quantity > 0) {
      addVariantToCart(values.shopifyId, quantity);
      setQuantity(0);
    }
  };
  /* eslint-disable no-unused-vars */
  return (
    <motion.div
      className={cn(
        styles,
        "card relative lg:flex items-center text-black mb-20"
      )}
      variants={motionContainer}
      initial="hidden"
      animate="visible"
    >
      <div
        className={cn(
          index % 2 === 0
            ? "lg:w-2/6 w-2/5 lg:order-last lg:pr-10"
            : "lg:w-3/12 w-2/5 lg:pl-10",
          "image text-center relative z-10"
        )}
      >
        <GatsbyImage image={getImage(product.images[0])} alt={product.title} />
        <motion.div
          className="price text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          ${product.variants[0].price}
        </motion.div>
      </div>
      <div>
        <motion.div
          className={cn(index % 2 === 0 ? "left" : "right", "info")}
          variants={index % 2 === 0 ? motionOptionsLeft : motionOptionsRight}
          transition={{ ease: "easeInOut" }}
        >
          <h2 className="title uppercase border border-black bg-white px-4 lg:px-12 py-4 relative z-2 font-bold">
            {product.title}
          </h2>
        </motion.div>
        <Formik
          initialValues={{
            shopifyId: product.variants[0].shopifyId,
            productId: product.variants[0].productId,
            variantId: product.variants[0].id,
            quantity: quantity,
          }}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            await new Promise((r) =>
              setTimeout((r) => {
                handleSubmit(values);
                setSubmitting(false);
              }, 500)
            );
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              submitForm,
              setFieldValue,
            } = props;
            return (
              <Form key={product.id}>
                <motion.fieldset
                  className="flex mt-6 order-first lg:rder-last"
                  variants={motionOptionsDown}
                  transition={{ ease: "easeInOut" }}
                >
                  <QuantityAdjuster
                    index={index}
                    quantity={quantity}
                    onIncrement={() => onIncrement(setFieldValue)}
                    onDecrement={() => onDecrement(setFieldValue)}
                  />
                  <Button
                    className="ml-6"
                    isSubmitting={isSubmitting}
                    quantity={quantity}
                  >
                    Add to cart
                  </Button>
                </motion.fieldset>
                {/* <Persist name={product.id} /> */}
                {/* <DisplayFormikState {...props} /> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
}
