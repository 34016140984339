export const quantityValues = ["2000", "4000", "6000", "8000"];
export const defaultCollectionId =
  "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2MzAzODg5NDI3Nw==";
export const showCollectionFilter = true;
export const boolValues = ["yes", "no"];
export const motionContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.4,
    },
  },
};
export const motionBagImage = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
  exit: { y: 20, opacity: 0 },
};
export const motionOptionsLeft = {
  hidden: { opacity: 0, x: 100 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const motionOptionsRight = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export const motionOptionsDown = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
  },
};
