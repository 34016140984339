import React from "react";
import { isBrowser } from "react-device-detect";
import cn from "classnames";
import { IdleQueue } from "idlize/IdleQueue.mjs";
import Children from "react-children-utilities";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Splitting from "splitting";
import { StoreContext } from "../../context/StoreContext";
import "splitting/dist/splitting.css";

import { text } from "./IntroHeading.module.scss";

export function IntroHeading({ currentSlide, setCurrentSlide,logo, children }) {
  const textRef = React.useRef(null);
  const { menuOpen } = React.useContext(StoreContext);

  React.useEffect(() => {
    const queue = new IdleQueue();
    queue.pushTask(() => {
      if (isBrowser) {
        Splitting();
      }
    });
  }, []);

  return (
    <div
      className={cn(
        text,
        "heading px-4 w-full h-screen absolute flex flex-col justify-center align-center items-center z-20",
        menuOpen ? "invisible" : "visible"
      )}
    >
      <div
        className="text-center text-white text-2xl max-w-4xl mx-auto"
        ref={textRef}
        data-splitting="words"
        aria-label={Children.onlyText(children)}
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(JSON.parse(children.raw)),
        }}
      />
      {logo &&
          <img style={{paddingBottom : 200, position: "absolute",bottom: 0 }}
               src={`https://${logo?.file?.url}`}
               alt={logo?.title}
               width={550} />
      }
    </div>
  );
}
