import React from "react";
import cn from "classnames";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IntroFlare } from "components";
import { StoreContext } from "../../context/StoreContext";

import { style } from "./LayoutSinglePage.module.scss";

export function LayoutSinglePage({ data, columns = 1, children }) {
  const richText = data?.body.raw ? JSON.parse(data.body.raw) : null;
  const { menuOpen } = React.useContext(StoreContext);

  return (
    <section className={cn(style, "z-20")}>
      {data?.background && (
        <>
          <IntroFlare currentSlide={0} />
          <GatsbyImage
            image={getImage(data.background)}
            alt={data.title}
            className="fixed -z-1 top-0 h-screen bg-image"
          />
        </>
      )}
      <div
        className={cn(
          "lg:container lg:mx-auto lg:max-w-6xl px-4 relative z-2 lg:flex items-center h-screen pb-32 pt-40",
          columns === 2 ? "lg:w-1/2" : "w-full",
          menuOpen ? "invisible" : "visible"
        )}
      >
        {richText && (
          <div
            className="lg:w-1/2 w-full text-white"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(richText),
            }}
          />
        )}
        {!richText && <>{children}</>}
        {columns === 2 && <div className="lg:w-1/2 w-full">{children}</div>}
      </div>
    </section>
  );
}
