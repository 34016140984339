import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

export function Footer({ footer = "fixed" }) {
  return (
    <footer className={cn(footer, "bottom-0 w-full z-10")}>
      <div className="container lg:max-w-4xl text-center text-md py-10 mx-auto">
        <Link to="/">What is R+R®?</Link>{" "}
        |{" "}
        <Link to="/how-it-works/">How it works</Link>{" "}
        |{" "}
        <Link to="/contact-us/">Contact us</Link> |{" "}
        <a
          href={`/downloads/research.zip`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Research
        </a>{" "}
        |{" "}
        <a
          href="https://www.savorbrands.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Savor Brands
        </a>{" "}
        |{" "}
        <Link to="/what-is-allowed-into/">What's allowed into</Link>{" "}
        |{" "}
        <a
          href="https://certification.rplusrprogram.com/"
          target="_blank"
          rel="noopener noreferrer"
        >Certification</a>
      </div>
    </footer>
  );
}
