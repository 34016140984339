import React from "react";
import cn from "classnames";
import { Field } from "formik";

export function QuantityAdjuster({
  index,
  quantity,
  onDecrement,
  onIncrement,
}) {
  return (
    <div
      className={cn(
        index % 2 === 0 ? "" : "ml-auto",
        "flex items-center bg-white border border-black"
      )}
    >
      <label
        htmlFor="custom-input-number"
        className="uppercase text-sm px-3 hidden lg:inline"
      >
        Quantity
      </label>
      <div className="custom-number-input w-32 lg:border-l border-black">
        <div className="flex flex-row w-full relative bg-transparent">
          <button
            onClick={onDecrement}
            type="button"
            className=" bg-transparent h-full w-20 cursor-pointer outline-none"
          >
            <span className="m-auto text-2xl">−</span>
          </button>
          <Field
            id="quantity"
            name="quantity"
            className="outline-none focus:outline-none text-center w-full bg-transparent text-sm hover:text-black focus:text-black md:text-basecursor-default flex items-center"
            value={quantity}
          ></Field>
          <button
            onClick={onIncrement}
            type="button"
            className="bg-transparent h-full w-20 cursor-pointer"
          >
            <span className="m-auto text-2xl">+</span>
          </button>
        </div>
      </div>
    </div>
  );
}
