import React from "react";
import { ProductsCard } from "components";
import { isMobile } from "react-device-detect";
import { Spinner } from "../../components";
import cn from "classnames";

export function ProductsGrid({ products }) {
  const [mobile, setMobile] = React.useState();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setMobile(isMobile);
  }, [setMobile]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timeout);
  }, [setLoading]);

  return (
    <>
      {products && (
        <section
          className={cn(
            loading ? "items-center" : "items-end",
            "container lg:mx-auto lg:flex content-center justify-center min-h-screen lg:pb-40 pt-40 lg:pt-56 lg:px-0 px-6"
          )}
        >
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <>
              {products.map((product, index) => (
                <ProductsCard
                  product={product.node}
                  index={mobile ? 1 : index}
                  key={product.node.shopifyId}
                />
              ))}
            </>
          )}
        </section>
      )}
    </>
  );
}
